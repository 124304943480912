#carosel1{
    /* background: linear-gradient(to left, #9d9db3 0%, #43304d 103%); */
    
    width: 1100px;
    height: 190px;
    margin-left: 270px;
    /* box-shadow:0 0 10px; */
      


}
#crozldiv1{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width:223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: 40px;
    



}
#crozldiv2{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -2px;



}
#crozldiv3{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -45px;



}
#crozldiv4{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -90px;


}

