#divmencard-0{
    /* background:#18141a;  */
    /* box-shadow: 1px 1px 10px #999;  */
    margin: auto; 
    text-align: center; 
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-top: 10px;
    width: 1200px;
    padding-top: 40px;
    padding-bottom: 20px ;
    overflow-x: scroll;
overflow-y: hidden;

white-space:nowrap
    
}
#divmencard-0 #card-1{
    /* box-shadow: 1px 1px 10px #999;  */
    margin: 2px;
    margin-left: 20px;
    max-height: 700px;
    cursor: pointer;
display:inline-block;
*display:inline;
*zoom:1;
vertical-align:top;

}

#card-1{
    background: radial-gradient(#1feef5, #134a68);
    box-shadow: 0 1.75rem 2rem -.4rem rgba(10, 21, 70, 0.993);
height: 300px;
width: 218px;
border-radius: 10px;
margin-left: 10px;
margin-top: 200px;




}

#card-1:hover{
    transform: translateY(-7px);
    /* transition-delay: 0.3s; */
    transition-duration: 0.5s;


}
#imgcard1{
border-radius: 10px 10px 0px 0px;

}
#hh4-1{
    margin-top: 15px;
color: rgb(245, 241, 238);
font-size: 19px;

}
#pp-1{
    color:rgb(94, 90, 112);
    margin-left: -120px;
}
#hhh4-2{
color: rgb(245, 241, 238);
font-size: 12px;
background-color: darkgray;
width: 50px;
height: 20px;
margin-left: 20px;


}

#place-Estate-h1{
color: blanchedalmond;
margin-left: -800px;
font-size: 25px     ;



}
#view-a1{
color: rgb(214, 5, 5);
margin-left: 1000px;
margin-top: 200px;

}



  *::-webkit-scrollbar {
    width: 10px;
    height: 11px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgba(17, 167, 12, 0.63);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(9, 57, 80);
    border-radius: 20px;
   
    width: 10px;
  }

 