#metaB1{
    margin-top: -3312px;
    margin-left: -460px;
    background-color: rgb(151, 10, 10);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB1:hover{
    background-color: rgb(255, 23, 2);
    color: rgb(12, 11, 11);
    box-shadow: 0 0 30px rgb(255, 12, 4);
    font-size: 18px;


}    



#metaB2{
    margin-top: -3360px;
    margin-left: 20px;
    background-color: rgb(135, 144, 6);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB2:hover{
    background-color: rgb(238, 255, 0);
    color: black;
    box-shadow: 0 0 30px rgb(255, 251, 0);
    font-size: 18px;


}    
#metaB3{
    margin-top: -3410px;
    margin-left: 500px;
    background-color: rgb(136, 7, 136);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB3:hover{
    background-color: rgb(255, 0, 255);
    color: black;
    box-shadow: 0 0 30px rgb(255, 0, 255);
    font-size: 18px;


}    
#metaB4{
    margin-top: -3458px;
    margin-left: 981px;
    background-color: rgb(23, 5, 104);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    
    }
    
#metaB4:hover{
    background-color: rgb(72, 21, 241);
    color: black;
    box-shadow: 0 0 30px rgb(51, 9, 148);
    font-size: 18px;


}    
