@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#men-div0{
/* background-color: rgb(24,20,26,255); */
width: 100%;
background-color: darkcyan;
/* height: 600px; */
/* border-bottom: solid 2px black; */
box-shadow: 0 0 10px;
}



#divmencard-0{
    /* background:#18141a;  */
    /* box-shadow: 1px 1px 10px #999;  */
    margin: auto; 
    text-align: center; 
    position: relative;
    border-radius: 5px;
    margin-top: 10px;
    width: 1200px;
    padding-top: 40px;
    padding-bottom: 20px ;
    overflow-x: scroll;
overflow-y: hidden;

white-space:nowrap
    
}
#divmencard-0 #card-1{
    /* box-shadow: 1px 1px 10px #999;  */
    margin: 2px;
    margin-left: 20px;
    max-height: 700px;
    cursor: pointer;
display:inline-block;
*display:inline;
*zoom:1;
vertical-align:top;

}

#card-1{
    background: radial-gradient(#1feef5, #134a68);
    box-shadow: 0 1.75rem 2rem -.4rem rgba(10, 21, 70, 0.993);
height: 300px;
width: 218px;
border-radius: 10px;
margin-left: 10px;
margin-top: 200px;




}

#card-1:hover{
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    /* transition-delay: 0.3s; */
    transition-duration: 0.5s;


}
#imgcard1{
border-radius: 10px 10px 0px 0px;

}
#hh4-1{
    margin-top: 15px;
color: rgb(245, 241, 238);
font-size: 19px;

}
#pp-1{
    color:rgb(94, 90, 112);
    margin-left: -120px;
}
#hhh4-2{
color: rgb(245, 241, 238);
font-size: 12px;
background-color: darkgray;
width: 50px;
height: 20px;
margin-left: 20px;


}

#place-Estate-h1{
color: blanchedalmond;
margin-left: -800px;
font-size: 25px     ;



}
#view-a1{
color: rgb(214, 5, 5);
margin-left: 1000px;
margin-top: 200px;

}



  *::-webkit-scrollbar {
    width: 10px;
    height: 11px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgba(17, 167, 12, 0.63);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(9, 57, 80);
    border-radius: 20px;
   
    width: 10px;
  }

 



#placetop-div-men0{
height: 300px;

}


#img-template{
/* border-bottom: solid 2px rgb(10, 15, 12); */

border-radius: 0 0 0% 55% / 50%;
/* box-shadow: 0 0 20px black; */
box-shadow: 0 1.75rem 2rem -.4rem rgba(2, 5, 63, 0.993)
    
 
}

/* #backgrond-div{

height: 300px;


filter: contrast(130%);
filter: saturate(170%);

border-bottom:solid 2px chartreuse;
box-shadow: 0 0 10px #000;

  background-color: #141114;
background-image: linear-gradient(335deg, black 23px, transparent 23px),
  linear-gradient(155deg, black 23px, transparent 23px),
  linear-gradient(335deg, black 23px, transparent 23px),
  linear-gradient(155deg, black 23px, transparent 23px);
background-size: 58px 58px;
background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;



} */


  .sign {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
    background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      #6b1839,
      transparent
    );
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    letter-spacing: 2;
    left: 302px;
    top: -32px;
    font-family: "Clip";
    text-transform: uppercase;
    font-size:35px;
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    -webkit-animation: shine 2s forwards, flicker 3s infinite;
            animation: shine 2s forwards, flicker 3s infinite;
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
    
    
  }
  
  @-webkit-keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
    28%,
    33% {
      color: #ff65bd;
      text-shadow: none;
    }
    82%,
    97% {
      color: #ff2483;
      text-shadow: none;
    }
  }
  
  @keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
    28%,
    33% {
      color: #ff65bd;
      text-shadow: none;
    }
    82%,
    97% {
      color: #ff2483;
      text-shadow: none;
    }
  }
  
  .flicker {
    -webkit-animation: shine 2s forwards, blink 3s 2s infinite;
            animation: shine 2s forwards, blink 3s 2s infinite;
  }
  
  .fast-flicker {
    -webkit-animation: shine 2s forwards, blink 10s 1s infinite;
            animation: shine 2s forwards, blink 10s 1s infinite;
  }
  
  @-webkit-keyframes shine {
    0% {
      color: #6b1839;
      text-shadow: none;
    }
    100% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
  }
  
  @keyframes shine {
    0% {
      color: #6b1839;
      text-shadow: none;
    }
    100% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
        0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    }
  }
  
  @-webkit-keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  
    68% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.9;
    }
  
    72% {
      opacity: 0.95;
    }
  
    93% {
      opacity: 0.93;
    }
  
    95% {
      opacity: 0.95;
    }
  
    97% {
      opacity: 0.93;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  
    68% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.9;
    }
  
    72% {
      opacity: 0.95;
    }
  
    93% {
      opacity: 0.93;
    }
  
    95% {
      opacity: 0.95;
    }
  
    97% {
      opacity: 0.93;
    }
  
    to {
      opacity: 1;
    }
  }
   /* l ve m eta v erse css end */

  .title {
	font-family: "Montserrat";
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 300px;
	letter-spacing: 1px;
  margin-top: -240px;
  margin-left: 100px;
}

h1 {
	background-image: url(https://c.tenor.com/lveDtTE8Ek8AAAAd/rainbow-swirls-relaxing.gif);
	background-size: cover;
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	text-transform: uppercase;
	font-size: 90px;
	line-height: .75;
	margin: 10px 0;
 

  
}
/* styling my button */

.white-mode {
	text-decoration: none;
	padding: 7px 10px;
	background-color: #122;
	border-radius: 3px;
	color: #FFF;
	transition: .35s ease-in-out;
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-family: "Montserrat";
}

.white-mode:hover {
	background-color: #FFF;
	color: #122;
}


#img-template-Land{
margin-top: -600px;


}
/* #nav-men{
background-color: rgb(25, 27, 59);
height: 50px;
padding: 10px;



}
#navb{

color: wheat;
text-decoration: none;
width: 400px;
font-size: 20px;


}

#navb :hover{
background-color: black;

}





  */

  nav{
    /* background-color: rgb(37, 32, 68); */
    padding: 10px;
    top: 0;
}
*{
    margin: 0;
}
#naw-a{
    padding: 10px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 20px;

    
}
#naw-a:hover{
    background-color: rgb(16, 170, 190);
    color: black;
    padding: 11px;
    border-radius: 10px;
    box-shadow: 0 0 10px black;
  
}

.fa-home{
    margin: 5px;
}
#srchbox{
position: absolute;
top: 80px;
left: 950px;
width: 300px;
padding: 7px;

}

#srchbox-B{
position: absolute;
top: 80px;
left: 1251px;
color: rgb(10, 10, 10);
background-color: rgb(2, 144, 187);
padding: 5px;
font-size: 20px;
border-radius: 2px;
}
/* #divmencard-0{
    background:#18141a; 
    
    margin: auto; 
    text-align: center; 
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-top: 10px;
    width: 1100px;
    padding-top: 40px;
    padding-bottom: 20px ;
    overflow-x: scroll;
overflow-y: hidden;

white-space:nowrap
    
}
#divmencard-0 #card-1{
    
    margin: 2px;
    margin-left: 20px;
    max-height: 700px;
    cursor: pointer;
display:inline-block;
*display:inline;
*zoom:1;
vertical-align:top;

}

#card-1{
    background-color:rgb(36,33,41,255);
height: 300px;
width: 218px;
border-radius: 10px;
margin-left: 20px;
margin-top: 200px;




}

#card-1:hover{
    transform: translateY(-7px);

    transition-duration: 0.5s;


}
#imgcard1{
border-radius: 10px 10px 0px 0px;

}
#hh4-1{
    margin-top: 15px;
color: rgb(245, 241, 238);
font-size: 19px;

}
#pp-1{
    color:rgb(94, 90, 112);
    margin-left: -120px;
}
#hhh4-2{
color: rgb(245, 241, 238);
font-size: 12px;
background-color: darkgray;
width: 50px;
height: 20px;
margin-left: 20px;


}

#place-Estate-h1{
color: blanchedalmond;
margin-left: -800px;
font-size: 30px     ;


}
#view-a1{
color: rgb(214, 5, 5);


} */
#carosel1{
    /* background: linear-gradient(to left, #9d9db3 0%, #43304d 103%); */
    
    width: 1100px;
    height: 190px;
    margin-left: 270px;
    /* box-shadow:0 0 10px; */
      


}
#crozldiv1{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width:223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: 40px;
    



}
#crozldiv2{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -2px;



}
#crozldiv3{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -45px;



}
#crozldiv4{
    box-shadow: 0 0 5px #000;
    height: 140px;
    width: 223px;
    background-color: rgb(56,99,129,255);
    color: wheat;
    margin-left: -90px;


}


.footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#282d32;
    box-shadow: 0 0 10px black;
  }
  
  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }
#buttn{
    margin-top: -3170px;
    margin-left: -950px;
    background-color: rgb(113, 206, 20);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
    #buttn:hover{
    background-color: chartreuse;
    color: black;
    box-shadow: 0 0 30px chartreuse;
    font-size: 18px;
    
    
    }
    
    
    #buttn-2{
        margin-top: -2160px;
        margin-left: -960px;
        background-color: rgb(113, 206, 20);
        color: rgb(245, 239, 239);
        border: none;
        width: 200px;
        box-shadow: 0 0 20px black;
        font-size: 18px;
        }
        
        #buttn-2:hover{
        background-color: chartreuse;
        color: black;
        box-shadow: 0 0 30px chartreuse;
        font-size: 18px;
        
        
        }


    #buttn-3{
        margin-top: -1180px;
        margin-left: -960px;
        background-color: rgb(113, 206, 20);
        color: rgb(245, 239, 239);
        border: none;
        width: 200px;
        box-shadow: 0 0 20px black;
        font-size: 18px;
        }
        
        #buttn-3:hover{
        background-color: chartreuse;
        color: black;
        box-shadow: 0 0 30px chartreuse;
        font-size: 18px;
        
        
        }
#metaB1{
    margin-top: -3312px;
    margin-left: -460px;
    background-color: rgb(151, 10, 10);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB1:hover{
    background-color: rgb(255, 23, 2);
    color: rgb(12, 11, 11);
    box-shadow: 0 0 30px rgb(255, 12, 4);
    font-size: 18px;


}    



#metaB2{
    margin-top: -3360px;
    margin-left: 20px;
    background-color: rgb(135, 144, 6);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB2:hover{
    background-color: rgb(238, 255, 0);
    color: black;
    box-shadow: 0 0 30px rgb(255, 251, 0);
    font-size: 18px;


}    
#metaB3{
    margin-top: -3410px;
    margin-left: 500px;
    background-color: rgb(136, 7, 136);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
#metaB3:hover{
    background-color: rgb(255, 0, 255);
    color: black;
    box-shadow: 0 0 30px rgb(255, 0, 255);
    font-size: 18px;


}    
#metaB4{
    margin-top: -3458px;
    margin-left: 981px;
    background-color: rgb(23, 5, 104);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    
    }
    
#metaB4:hover{
    background-color: rgb(72, 21, 241);
    color: black;
    box-shadow: 0 0 30px rgb(51, 9, 148);
    font-size: 18px;


}    

