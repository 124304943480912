#buttn{
    margin-top: -3170px;
    margin-left: -950px;
    background-color: rgb(113, 206, 20);
    color: rgb(245, 239, 239);
    border: none;
    width: 200px;
    box-shadow: 0 0 20px black;
    font-size: 18px;
    }
    
    #buttn:hover{
    background-color: chartreuse;
    color: black;
    box-shadow: 0 0 30px chartreuse;
    font-size: 18px;
    
    
    }
    
    
    #buttn-2{
        margin-top: -2160px;
        margin-left: -960px;
        background-color: rgb(113, 206, 20);
        color: rgb(245, 239, 239);
        border: none;
        width: 200px;
        box-shadow: 0 0 20px black;
        font-size: 18px;
        }
        
        #buttn-2:hover{
        background-color: chartreuse;
        color: black;
        box-shadow: 0 0 30px chartreuse;
        font-size: 18px;
        
        
        }


    #buttn-3{
        margin-top: -1180px;
        margin-left: -960px;
        background-color: rgb(113, 206, 20);
        color: rgb(245, 239, 239);
        border: none;
        width: 200px;
        box-shadow: 0 0 20px black;
        font-size: 18px;
        }
        
        #buttn-3:hover{
        background-color: chartreuse;
        color: black;
        box-shadow: 0 0 30px chartreuse;
        font-size: 18px;
        
        
        }