/* #nav-men{
background-color: rgb(25, 27, 59);
height: 50px;
padding: 10px;



}
#navb{

color: wheat;
text-decoration: none;
width: 400px;
font-size: 20px;


}

#navb :hover{
background-color: black;

}





  */

  nav{
    /* background-color: rgb(37, 32, 68); */
    padding: 10px;
    top: 0;
}
*{
    margin: 0;
}
#naw-a{
    padding: 10px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 20px;

    
}
#naw-a:hover{
    background-color: rgb(16, 170, 190);
    color: black;
    padding: 11px;
    border-radius: 10px;
    box-shadow: 0 0 10px black;
  
}

.fa-home{
    margin: 5px;
}
#srchbox{
position: absolute;
top: 80px;
left: 950px;
width: 300px;
padding: 7px;

}

#srchbox-B{
position: absolute;
top: 80px;
left: 1251px;
color: rgb(10, 10, 10);
background-color: rgb(2, 144, 187);
padding: 5px;
font-size: 20px;
border-radius: 2px;
}