#men-div0{
/* background-color: rgb(24,20,26,255); */
width: 100%;
background-color: darkcyan;
/* height: 600px; */
/* border-bottom: solid 2px black; */
box-shadow: 0 0 10px;
}


